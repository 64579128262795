"use client";

import { useEffect, useState } from "react";

export default function Clock() {
  const [date, setDate] = useState<Date>(new Date());
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);

  function displayTime() {
    const estTime = new Date(
      date.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    return (
      estTime.getHours().toString().padStart(2, "0") +
      ":" +
      estTime.getMinutes().toString().padStart(2, "0")
    );
  }

  function displayDate() {
    const estDate = new Date(
      date.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    return (
      estDate.getFullYear() +
      "/" +
      (estDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      estDate.getDate().toString().padStart(2, "0")
    );
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (intervalID === undefined) {
        setIntervalID(
          setInterval(() => {
            setDate(new Date());
          }, 1000)
        );
      } else {
        clearInterval(intervalID);
      }
    }
  }, [isLoading]);

  return (
    <div
      className="flex grid grid-rows-2 text-center"
      onLoad={() => setIsLoading(false)}
    >
      <p>{displayTime()}</p>
      <p>{displayDate()}</p>
    </div>
  );
}
