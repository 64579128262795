"use client";

import Logo from "../../../public/image.webp";
import DarkLogo from "../../../public/logo-darkmode.png";
import Image from "next/image";
import { useLocale } from "next-intl";
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";
import { FC } from "react";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Footer: FC<Props> = ({ fqcn_bui }) => {
  const locale = useLocale();

  return (
    <a id={getFQCN(fqcn_bui, "link-logo")} href={`/${locale}`}>
      <div className="bg-white border border-gray-300 rounded pt-2 scale-80 shadow m-3 pb-2 w-32 dark:bg-dark-mode">
        <div className="flex place-content-center">
          <Image
            id={getFQCN(fqcn_bui, "light-logo")}
            src={Logo}
            width={80}
            alt="phpcreation logo"
            className="block dark:hidden"
          />

          <Image
            id={getFQCN(fqcn_bui, "dark-logo")}
            src={DarkLogo}
            width={80}
            alt="phpcreation logo"
            className="hidden dark:block"
          />
        </div>
      </div>
    </a>
  );
};

export default Footer;
