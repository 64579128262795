// React
import React, { FC, useContext } from "react";

// Next
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";

// JS Cookie
import cookie from "js-cookie";

// React Icons
import { HiLogin, HiLogout } from "react-icons/hi";

// Components
import { Button } from "@/components/ui/button";
import ModeToggle from "./ModeToggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";
import { RolesContext } from "@/contexts/RolesContext";
import { UserContext, UserContextType } from "@/contexts/UserContext";
import { Skeleton } from "./skeleton";
import logout from "@/lib/Logout";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Navbar: FC<Props> = ({ fqcn_bui }) => {
  const t = useTranslations("Navbar");
  const pathname = usePathname();

  const { fullName, isLoading } = useContext<UserContextType>(UserContext);
  const { arrayRoles } = useContext<any>(RolesContext);

  return (
    <article className="w-full p-3 flex justify-end items-end print:hidden bg-gray-300 dark:bg-slate-700">
      <div className="flex gap-4">
        {(pathname.includes("task-manager") ||
          pathname.includes("task-details")) && (
          <div className="flex">
            <div className="mr-4 m-auto">
              {isLoading && !arrayRoles ? (
                <Skeleton
                  id={getFQCN(fqcn_bui, "employee-skeleton")}
                  className="bg-muted h-6 w-[250px]"
                />
              ) : (
                <>
                  {arrayRoles?.ROLE_USER === "ROLE_USER" && fullName && (
                    <p>{t("Welcome") + " " + fullName}</p>
                  )}
                </>
              )}
            </div>

            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      id={getFQCN(fqcn_bui, "logout-button")}
                      name="logout-button"
                      className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue"
                      onClick={() => logout()}
                    >
                      <HiLogin
                        id={getFQCN(fqcn_bui, "logout-icon")}
                        className="w-5 h-5 text-white print:text-black"
                      />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("Logout")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        )}

        <ModeToggle
          fqcn_bui={{
            Bundle: "navbarBundle",
            Unit: "navBar",
            Interface: "modeToggle",
          }}
        />
      </div>
    </article>
  );
};

export default Navbar;
